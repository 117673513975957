/* Services Section Styling */
.services-section {
    padding: 60px 20px;
    background-image: url('../../assets/img/services/services-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color:#fff;
  }
  
  .services-section .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .section-title {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-title h2 {
    font-size: 28px;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .section-title p {
    font-size: 18px;
    color: #666;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  .service-card {
    background: linear-gradient(90deg, #eee, #fff);
    //border: 1px solid #ddd;
    border-radius: 12px;
    padding: 30px 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.3s ease;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    overflow: hidden;
  }
  
  .service-card:hover {
    transform: translateY(-10px) scale(1.03);
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
    //background: linear-gradient(30deg, #4794A9, #ddd);
  }

  .service-card::before {
    content: "";
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(0, 180, 216, 0.3), transparent 70%);
    opacity: 0;
    transition: opacity 0.5s ease;
    z-index: 0;
  }

  .service-card:hover::before {
    //opacity: 1;
  }

  .icon-container {
    font-size: 50px;
    color: #00b4d8;
    margin-bottom: 15px;
    transition: color 0.3s ease;
    z-index: 1;
  }

  .service-card:hover .icon-container {
    color: #00b4d8;
  }
  
  .service-card h3 {
    font-size: 20px;
    color: #00b4b8;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-weight: 500;
    z-index: 1;
  }
  
  .service-card p {
    font-size: 16px;
    color: #555;
    line-height: 1.5;
    z-index: 1;
  }

  @keyframes fadeInUp {
    from {
        transform: translateY(10%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
  }

  .service-card {
    animation: fadeInUp 0.5s ease;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .services-grid {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  
    .service-card {
      margin-bottom: 20px;
    }
  }