/* Footer general styles */
.footer {
    background-color: #1c2331;
    color: white;
    font-family: Arial, sans-serif;
  }
  
  /* Social Media Section */
  .social-media-section {
    width:100%;
    background-color: #6351ce;
    padding: 0px 0;
  }

  .social-media-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 20px;
    box-sizing: border-box;
  }
  
  .social-media-left {
    flex: 1;
    text-align:left;
  }
  
  .social-media-right {
    display: flex;
    gap: 30px;
    justify-content: flex-end;
  }
  
  .social-icon {
    color: white;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .social-icon:hover {
    color: #7c4dff;
  }
  
  /* Links Section */
  .links-section {
    padding: 3rem 0;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .col-md-3, .col-md-2, .col-md-4 {
    flex: 1;
    margin: 1rem;
  }
  
  h6 {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.1rem;
    color: #fff;
  }

  
  hr.divider {
    width: 60px;
    background-color: #7c4dff;
    height: 2px;
    margin: 1rem 0;
  }

  .text{
    font-size: 14px;
  }
  
  p a {
    color: #ccc;
    text-decoration: none;
    margin: 0.5rem 0;
  }
  
  p a:hover {
    color: white;
  }
  
  /* Copyright Section */
  .copyright {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 1rem 0;
    font-size: 14px;
  }