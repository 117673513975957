/* .service-section {
    background-image: url('../../assets/img/services/service-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 800px;
} */

/* Service Section */
.service-section {
    background-image: url('../../assets/img/pricing/pricing-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
    padding: 50px 20px;
    text-align: center;
    height:auto;
  }
  
  /* Container */
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  /* Headings */
  .service-headings {
    margin-bottom: 40px;
  }
  
  .small-heading {
    color: #0056b3; /* Blue color */
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .large-heading {
    color: #fff; /* White color */
    font-size: 36px;
    font-weight: bold;
    line-height: 1.4;
  }
  
  /* Cards Container */
  .service-cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }
  
  /* Individual Card */
  /* .card {
    background: #fff;
    color: #333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: left;
    font-size: 16px;
  } */

  .card {
    position: relative;
    min-width: 300px;
    height: auto;
    overflow: hidden;
    border-radius: 15px;
    margin: 0 auto;
    padding: 40px 20px;
    box-shadow: 0 10px 15px rgba(0,0,0,0.3);
    transition: .5s;
  }
  .card:hover {
    transform:scale(1.1);
  }
  .card_red, .card_red .title .fa {
    background: linear-gradient(-45deg, #ffec61, #f321d7);
  }
  .card_violet, .card_violet .title .fa  {
    background: linear-gradient(-45deg, #f403d1, #64b5f6);
  }
  .card_three, .card_three .title .fa  {
    background: linear-gradient(-45deg, #24ff72, #9a4eff);
  }
  
  .card:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: rgba(255, 255, 255, .1);
    z-index: 1;
    transform: skewY(-5deg) scale(1.5);
  }
  
  .title .fa {
    color: #fff;
    font-size: 60px;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    text-align: center;
    line-height: 100px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
  }
  .title h2 {
    position: relative;
    margin: 20px 0 0;
    padding: 0;
    color: #fff;
    font-size: 28px;
    z-index: 2;
  }
  .price {
    position: relative;
    z-index: 2;
  }
  .price h4 {
    margin: 0;
    padding: 20px 0;
    color: #fff;
    font-size: 4vw;
  }
  .option {
    position: relative;
    z-index: 2;
  }
  .option ul {
    margin: 0;
    padding: 0;
  }
  .option ul li {
    margin: 0 0 10px;
    padding: 0;
    list-style: none;
    color: #fff;
    font-size: 16px;
  }
  .card a {
    display: block;
    position: relative;
    z-index: 2;
    background-color: #fff;
    color: #262ff;
    width: 150px;
    height: 40px;
    text-align: center;
    margin: 20px auto 0;
    line-height: 40px;
    border-radius: 40px;
    font-size: 16px;
    cursor: pointer;
    text-decoration: none;
    box-shadow: 0 5px 10px rgba(0,0,0, .1);
  }
  .card a:hover {
    
  }
  
  /* Responsive Design */
  @media (max-width: 992px) {
    .service-cards {
      grid-template-columns: repeat(2, 1fr); /* Two cards per row */
    }
  
    .large-heading {
      font-size: 28px; /* Adjust heading size for smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .service-cards {
      grid-template-columns: 1fr; /* One card per row */
    }
  
    .large-heading {
      font-size: 24px;
    }
  }