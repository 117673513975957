/* _banner.css */
.banner {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 50px;
    width: 100%;
    height:auto;
    aspect-ratio: 16 / 9;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .banner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //background: rgba(0, 0, 0, 0.5);
  }
  
  .banner-content {
    position: relative;
    max-width: 50%;
    text-align: left;
    margin-left:12%;
  }
  .banner-content h3 {
    text-transform:uppercase;
    color:#FFF;
    font-weight: 100;
    font-size: clamp(14px, 2vw, 18px);
    margin-bottom: 10px;
  }
  .banner-content h1 {
    text-transform: uppercase;
    color:#FFF;
    margin-bottom: 20px;
    font-size: clamp(28px, 4vw, 50px);
    font-family: 'Calibri';
    font-weight: 600;
  }
  
  .description {
    font-size: clamp(14px, 2vw, 18px);
    color: #aaa;
    margin-bottom: 30px;
    font-family: calibri;
  }
  
  .banner-image img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .banner {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .banner-content {
      max-width: 100%;
      padding-right: 0;
    }
  
    .banner-image {
      margin-top: 20px;
    }
  }