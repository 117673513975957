  /* Form Popup Overlay */
  .form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Form Popup Styling */
  .form-popup {
    background: white;
    border-radius: 10px;
    padding: 20px;
    width: 500px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Close Button */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #007bff;
  }
  
  /* Form Styling */
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .form-group label {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    text-align:left;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  textarea {
    resize: none;
    height: 80px;
  }
  
  .submit-btn {
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .submit-btn:hover {
    background: #0056b3;
  }

  .error {
    color:red;
    font-size: 0.85rem;
    margin-top: 5px;
  }

  .success-message {
    color:green;
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .submit-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }