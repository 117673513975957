/* Navigation Styles */
.navbar {
    background-color: #ffffff;
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.nav-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    /* font-size: 1.5rem;
    font-weight: bold;
    color: #333; */
    text-decoration: none;
    display: flex;
    align-items: center;
}

.nav-logo {
    display: block;
    height: auto;
    object-fit: contain;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.nav-links a {
    color: #333;
    font-size: 15px;
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: #007bff;
}

.quote-btn {
    background-color: #007bff;
    color: white;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.quote-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
}

.hamburger {
    display: none;
    cursor: pointer;
    flex-direction: column;
    gap: 6px;
    background: none;
    border: none;
    padding: 0;
}

.hamburger span {
    width: 25px;
    height: 3px;
    background-color: #333;
    transition: all 0.3s ease;
    display: block;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .nav-links {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background-color: white;
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    }

    .nav-links.active {
        display: flex;
    }

    .hamburger.active span:nth-child(1) {
        transform: rotate(45deg) translate(8px, 6px);
    }

    .hamburger.active span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active span:nth-child(3) {
        transform: rotate(-45deg) translate(8px, -6px);
    }
}